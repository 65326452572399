<template>
  <div>
    <img src= @/assets/test.png height="300" width="100%" />

    <v-container style="margin-top: 20px">
      <section class="why">
        <v-row>
          <v-col>
            <h3>Over jouwbedrijfsmakelaar</h3>

            <p>
              Over ons bedrijf Jouwbedrijfsmakelaar, een digitale
              ontmoetingsplaats voor vraag en aanbod van bedrijfs onroerend
              goed. Of je nu een bedrijfspand wilt verkopen of verhuren of juist
              op zoek bent als bedrijf naar geschikte locatie, bij
              Jouwbedrijfsmakelaar kan het allemaal. Wij zorgen dat zowel
              verkoper, verhuurder en koper, huurder elkaar eenvoudig kunnen
              treffen via ons platform. Geen tussenkomst van dure
              bedrijfsmakelaars of andere verhuurinstanties, maar rechtstreeks
              contact. Deze korte lijnen leveren een direct kostenbesparing op.
            </p>

            <v-btn color="warning" elevation="11" large raised rounded text>
              <router-link to="/Overons">
                Meer over jouwbedrijfsmakelaar</router-link
              >
            </v-btn>
          </v-col>
          <v-col>
            <v-card class="mx-auto" max-width="400" elevation="9" shaped>
              <v-img
                class="white--text align-end"
                height="300px"
                src="@/assets/about.png"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <section class="about">
        <h3 style="text-align: center">
          Waarom kiezen voor de jouwbedrijfsmakelaar?
        </h3>
        <v-row>
          <v-col>
            <v-card elevation="2" shaped>
              <v-card-title class="icon">
                <v-icon large color="green darken-2"> mdi-domain </v-icon>
              </v-card-title>
              <v-card-title class="title">
                Professionele Ondersteuning
              </v-card-title>
              <v-card-subtitle class="subtitle">
                Onze adviseurs staan voor u klaar. Neem contact met ons op voor
                meer Informatie</v-card-subtitle
              >
              <v-card-actions>
                <div class="text-center">
                  <router-link to="/Contact">
                    <v-btn color="error" class="button">
                      Informatie gesprek aanvragen
                    </v-btn>
                  </router-link>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="2" shaped>
              <v-card-title class="icon">
                <v-icon large color="green darken-2">
                  mdi-arrow-up-bold-box-outline</v-icon
                >
              </v-card-title>
              <v-card-title class="title">
                Publicatie van jouw bedrijfspand
              </v-card-title>
              <v-card-subtitle class="subtitle">
                Wij zorgen ervoor dat jouw pand overal op komt te
                staan.</v-card-subtitle
              >
              <v-card-actions>
                <div class="text-center">
                  <router-link to="/Contact">
                    <v-btn color="error" class="button">
                      Informatie gesprek aanvragen
                    </v-btn>
                  </router-link>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="2" shaped>
              <v-card-title class="icon">
                <v-icon large color="green darken-2"> mdi-gavel </v-icon>
              </v-card-title>
              <v-card-title class="title"> Duidelijke afspraken </v-card-title>

              <v-card-subtitle class="subtitle">
                Wij rekenen géén maandelijkse kosten. Geen
                verassingen!</v-card-subtitle
              >
              <v-card-actions>
                <div class="text-center">
                  <router-link to="/Contact">
                    <v-btn color="error" class="button">
                      Informatie gesprek aanvragen
                    </v-btn>
                  </router-link>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </section>

      <section class="actueel">
        <h2>Recent toegevoegd</h2>

        <v-row>
          <v-col
            lg="3"
            md="4"
            sm="6"
            cols="12"
            v-for="building in buildings"
            :key="building.house_id"
          >
            <v-card class="mx-auto" max-width="400">
              <v-img
                :src="
                  building.images[0]
                    ? `https://back-end.mcmakelaardij.nl/static/${building.images[0].filename}`
                    : require('@/assets/no-house-image.png')
                "
                img-alt="Image"
                img-top
                tag="article"
                class="h-100"
              >
              </v-img>
              <v-card-title> {{ building.adres }} </v-card-title>

              <v-card-text class="text--primary">
                <div>{{ building.prijs }}</div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="orange"
                  block
                  :to="`/Single/${building.house_id}`"
                >
                  Bekijk dit pand</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </v-container>
  </div>
</template>
<script>
  import axios from 'axios';
  export default {
    data: () => {
      return {
        buildings: [],
        typeValue: '',
        typeStatus: false,
        typeArray: ['Amsterdam', 'Rotterdam', 'Den Haag', 'Gouda', 'Utrecht'],
        typingSpeed: 200,
        erasingSpeed: 100,
        newTextDelay: 2000,
        typeArrayIndex: 0,
        charIndex: 0,
      };
    },
    async mounted() {
      await this.getHouses();
    },
    methods: {
      async getHouses() {
        await axios
          .get('https://back-end.mcmakelaardij.nl/api/company/home')
          .then(response => (this.buildings = response.data));
      },
      typeText() {
        if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
          if (!this.typeStatus) this.typeStatus = true;
          this.typeValue += this.typeArray[this.typeArrayIndex].charAt(
            this.charIndex
          );
          this.charIndex += 1;
          setTimeout(this.typeText, this.typingSpeed);
        } else {
          this.typeStatus = false;
          setTimeout(this.eraseText, this.newTextDelay);
        }
      },
      eraseText() {
        if (this.charIndex > 0) {
          if (!this.typeStatus) this.typeStatus = true;
          this.typeValue = this.typeArray[this.typeArrayIndex].substring(
            0,
            this.charIndex - 1
          );
          this.charIndex -= 1;
          setTimeout(this.eraseText, this.erasingSpeed);
        } else {
          this.typeStatus = false;
          this.typeArrayIndex += 1;
          if (this.typeArrayIndex >= this.typeArray.length)
            this.typeArrayIndex = 0;
          setTimeout(this.typeText, this.typingSpeed + 1000);
        }
      },
    },
    created() {
      setTimeout(this.typeText, this.newTextDelay + 200);
    },
  };
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
  .button {
    margin-left: 20px;
  }
  .actueel {
    margin-top: 40px;
  }
  .about {
    padding-top: 1rem;
  }
  .color {
    color: orange;
  }
  .subtitle {
    color: black;
    font-weight: 900;
    font-size: 15px;
  }
  .title {
    color: orange;
    font-weight: 50px;
    text-align: center;
  }
  .icon {
    text-align: center;
    margin-left: 150px;
  }

  .actueel {
    text-align: center;
  }

  .about {
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .wrapper {
    display: flex;
  }

  header {
    position: absolute;
    z-index: 10;
    width: 100%;
  }
  .navbar {
    padding: 20px 0px;
  }

  .navbar-brand {
    font-weight: 800;
    text-transform: uppercase;
  }
  .nav-item {
    font-weight: 800;
  }
  .navbar-light .navbar-nav .nav-item .nav-link:hover,
  .navbar-light .navbar-nav .nav-item.active .nav-link {
    background: #f7931e;
    color: #fff;
  }

  .navbar-light .navbar-nav .nav-item.active .nav-link {
    background: #262626;
    padding: 8px 15px;
  }

  .navbar-light .navbar-toggler {
    border: none;
    color: fff;
    border: 2px solid #f7f7f7;
    border-radius: 0;
    outline: none;
  }

  @media (max: width 768px) {
    .banner {
      position: relative;
      background-image: url(' ~@/assets/banner.jpg');
      min-height: 100vh;
      background-size: cover;
      background-position: center;
      padding: 250px 0 200px;
    }
  }

  h2 span.typed-text {
    color: orange;
  }

  span.cursor {
    display: inline-block;
    margin-left: 3px;
    width: 4px;
    background-color: white;
    animation: cursorBlink 1s infinite;
  }
  span.cursor.typing {
    animation: none;
  }

  @keyframes cursorBlink {
    49% {
      background-color: white;
    }
    50% {
      background-color: transparent;
    }
    99% {
      background-color: transparent;
    }
  }
</style>
