<template>
  <HomePage />
</template>

<script>
import HomePage from "@/components/view/HomePage";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    HomePage,
  },
  metaInfo: {
    title: "Welkom bij Jouwbedrijfsmakelaar | bedrijfspanden in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>